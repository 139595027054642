import {
  Body,
  Button,
  Col,
  Grid,
  H2,
  HDisplay,
  LargeSeparator,
  Row
} from 'atomic'
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import {
  BackgroundWithFoldHalfWidthStyled,
  BackgroundWithFoldStyled
} from 'site/src/components/atm.background/background.styled'
import { OverflowXHiddenWrapper } from 'site/src/components/atm.wrapper/wrapper.component'
import { ArticleListItem } from 'site/src/components/mol.article-list-item/article-list-item.component'
import {
  CosmicjsHomeMedico,
  CosmicjsArtigosCientificosConnection
} from 'site/src/data/graphql/graphql-types'
import { appPaths } from 'utils/path'
import { doctorUrlPrefix } from 'utils/url'

interface HomeMedicalEducationSectionProps {
  cosmicjsHomeMedico?: CosmicjsHomeMedico
  allCosmicjsArtigosCientificos?: CosmicjsArtigosCientificosConnection
}

const DoctorHomeMedicalEducationSection: React.FunctionComponent<HomeMedicalEducationSectionProps> = props => {
  const cosmicjsHomeMedico = props.cosmicjsHomeMedico.metadata
  return (
    <OverflowXHiddenWrapper>
      <Grid position={'relative'}>
        <BackgroundWithFoldHalfWidthStyled />

        <Row noGutter>
          <Col sm={6} xs={12} gutter>
            <LargeSeparator />
            <Row mb={true}>
              <Col xs={12}>
                <H2>{cosmicjsHomeMedico.artigosAssunto}</H2>
              </Col>
            </Row>
            <Row mb={true}>
              <Col xs={12}>
                <HDisplay> {cosmicjsHomeMedico.artigosTitulo}</HDisplay>
              </Col>
            </Row>

            <Row mb={true}>
              <Col xs={12}>
                {props.allCosmicjsArtigosCientificos.edges.map(article => (
                  <Row mb={true} key={article.node.title}>
                    <Col xs={12}>
                      <ArticleListItem
                        title={article.node.title}
                        description={article.node.metadata.descricaoSimples}
                        link={`${doctorUrlPrefix}/${article.node.type_slug}/${article.node.slug}`}
                      />
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
            <Row mb={true}>
              <Col xs={12}>
                <Button
                  id='button-see-all-articles-doctor-home'
                  kind='secondary'
                  to={appPaths.scientificArticles.path.doctorUrl}
                >
                  Ver todos artigos científicos
                </Button>
              </Col>
            </Row>
            <LargeSeparator />
          </Col>

          <BackgroundWithFoldStyled as={Col} sm={6} xs={12}>
            <LargeSeparator />
            <Row mb={true}>
              <Col xs={12}>
                <H2 kind='light'>{cosmicjsHomeMedico.fleuryLabAssunto}</H2>
              </Col>
            </Row>
            <Row mb={true}>
              <Col xs={12}>
                <HDisplay kind='light'>
                  {' '}
                  {cosmicjsHomeMedico.fleuryLabTitulo}
                </HDisplay>
              </Col>
            </Row>
            <Row mb={true}>
              <Col xs={12}>
                <Body kind='light'>
                  {cosmicjsHomeMedico.fleuryLabDescricao}
                </Body>
              </Col>
            </Row>
            <Row mb={true}>
              <Col xs={12}>
                <Button
                  id='button-see-more-doctor-home'
                  kind='secondary'
                  light
                  external
                  to={cosmicjsHomeMedico.fleuryLabLink}
                >
                  Ver mais
                </Button>
              </Col>
            </Row>
            <LargeSeparator />
          </BackgroundWithFoldStyled>
        </Row>
      </Grid>
    </OverflowXHiddenWrapper>
  )
}

export default DoctorHomeMedicalEducationSection
